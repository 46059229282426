<template>
  <v-theme-provider dark>
    <base-section
      id="info-only"
    >
      <v-container>
        <base-business-contact-horizontal />
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBusinessInfoOnly',
  }
</script>
